<template>
  <div class="footer row flex-column-center align-column-center" v-scrollanimation>
    <div :class="modal.class">
      <div class="box">
          <div class="body">
            <font-awesome-icon
              :icon="['fas', 'times-circle']"
              class="icon-cv"
              @click="closeModal"
            />
            <span class="title_section titulo" v-scrollanimation>Forma parte de NEDETEL</span>
            <form action="#" @submit.prevent="sendCV" class="flex-column-center">
              <div class="row-inputs flex-row-center" v-scrollanimation>
                <div class="middle a">
                  <div class="label flex-row-center align-row-center">
                    <span class="title_section">Nombres Completos <span class="obl title_section">*</span></span>
                  </div>
                  <input type="text" :placeholder="form.names.placeholder" v-model="form.names.value" required>
                </div>
                <div class="middle b">
                  <div class="label flex-row-center align-row-center">
                    <span class="title_section">Apellidos Completos <span class="obl title_section">*</span></span>
                  </div>
                  <input type="text" :placeholder="form.surnames.placeholder" v-model="form.surnames.value" required>
                </div>
              </div>
              <div class="row-inputs flex-row-center" v-scrollanimation>
                <div class="middle a">
                  <div class="label flex-row-center align-row-center">
                    <span class="title_section">Correo Electrónico <span class="obl title_section">*</span></span>
                  </div>
                  <input type="email" :placeholder="form.email.placeholder" v-model="form.email.value" required>
                </div>
                <div class="middle b">
                  <div class="label flex-row-center align-row-center">
                    <span class="title_section">Teléfono <span class="obl title_section">*</span></span>
                  </div>
                  <input type="text" :placeholder="form.phone.placeholder" v-model="form.phone.value" required>
                </div>
              </div>
              <div class="label flex-row-center align-row-center" v-scrollanimation>
                <span class="title_section">Mensaje</span>
              </div>
              <textarea cols="30" rows="10" :placeholder="form.message.placeholder" v-model="form.message.value" v-scrollanimation></textarea>

              <div class="label flex-row-center align-row-center" v-scrollanimation>
                <span class="title_section">Hoja de Vida <span class="obl title_section">*</span></span>
              </div>

              <div class="label flex-row-center align-row-center" v-scrollanimation>
                <span class="title_section">El nombre del archivo debe cumplir con el siguiente formato Ej. Apellidos_Nombres.pdf</span>
              </div>

              <input type="file" accept=".pdf" ref="doc" @change="loadSettingsDocs" required v-scrollanimation>

              <input type="submit" class="btn" value="Enviar" v-scrollanimation>

              <Status
                :mostrar="status.mostrar"
                :tipo="status.tipo"
                :mensaje="status.mensaje"
                :esperando="status.waiting"
                @changeStatus="changeStatus"
              ></Status>
            </form>
          </div>
      </div>
    </div>

    <div class="titulos flex-row-center" v-scrollanimation>
      <div class="box first flex-row-center align-center">
        <div class="cont-img">
          <img src="../assets/img/logos/logo-nedetel.png" alt="" />
        </div>
      </div>
      <div class="box second flex-row-center">
        <span class="item title_section">Nosotros</span>
      </div>
      <div class="box third flex-row-center">
        <span class="item title_section">Enlaces</span>
      </div>
      <div class="box four flex-row-center">
        <span class="item title_section">Documentos</span>
      </div>

      <div class="box five flex-row-center last">
        <span class="item title_section">Calidad del Servicio</span>
      </div>

      <div class="box five flex-row-center last">
        <span class="item title_section">Info adicional</span>
      </div>
    </div>

    <div class="barra" v-scrollanimation>
      <span></span>
    </div>

    <div class="titulos flex-row-center" v-scrollanimation>
      <div class="box first subitems flex-column-center a" v-scrollanimation>
        <span class="phone">+593 (4) 6008192</span>
        <span class="mail">info@nedetel.net</span>

        <div class="social flex-row-center">
          <a href="https://www.facebook.com/Nedetel-111757390641044" target="_blank">
            <font-awesome-icon :icon="['fab', 'facebook']" class="icon" />
          </a>
          <a href="https://twitter.com/NEDETEL" target="_blank">
            <font-awesome-icon :icon="['fab', 'twitter']" class="icon" />
          </a>
          <a href="https://www.linkedin.com/company/nedetel/" target="_blank">
            <font-awesome-icon :icon="['fab', 'linkedin']" class="icon" />
          </a>
        </div>
      </div>
      <div class="box second subitems flex-column-center align-column-start b" v-scrollanimation>
        <a :href=path>Home</a>
        <a :href="path + '?section=0'">Nedetel</a>
        <a :href="path + '?section=2'">Partners</a>
        <a :href="path + '?section=4'">Clientes</a>
        <a :href="path + '?section=7'">Oficinas</a>
        <a :href="path + '?section=5'">Reseñas</a>
      </div>
      <div class="box third subitems flex-column-center c" v-scrollanimation>
        <a href="uploads/NORMA-DE-CALIDAD.pdf" target="_blank">
          Normas de calidad SVA
        </a>
        <a href="uploads/REGLAMENTO-ABONADOS.pdf" target="_blank">
          Reglamentos de abonados
        </a>
        <a href="https://www.presidencia.gob.ec/" target="_blank">
          Presidencia de la República del Ecuador 
        </a>
        <a href="https://www.telecomunicaciones.gob.ec/" target="_blank">
          Ministerio de Telecomunicaciones y Sociedad de la Información
        </a>
        <a href="https://nedetel.speedtestcustom.com/" target="_blank">
          Speedtest
        </a>
      </div>
      <div class="box four subitems flex-column-center d" v-scrollanimation>
        <a href="uploads/Seguridad-Informática.pdf" target="_blank">Seguridad Informática</a>
        <a href="uploads/PORCENTAJE-DE-CONSUMOS-CAPACIDAD-2024.docx" target="_blank">Porcentaje de capacidad Contratada & Consumida</a>
        <a href="uploads/Ecuador-Contrato Marcov1.528022023MODELO.pdf" target="_blank">Modelo de contrato tipo de adhesion de Servicio</a>
        <a href="uploads/LEY-ADULTO-MAYOR.pdf" target="_blank">Ley organica para adulto mayor</a>
        <a href="uploads/LEY-ORGANICA-DE-PROTECCION-DE-DATOS-PERSONALES.pdf" target="_blank">Ley organica de proteccion de datos personales</a>
        <a href="uploads/politica-de-privacidad-ecuador-rev-LGG06072023.pdf" target="_blank">Política de protección de datos personales Ufinet</a>
        <a href="uploads/NORMA-TECNICA-QUE-REGULA-LOS-CONTRATOS-DE-ADHESION-DE-SERVICIOS.pdf" target="_blank">Norma técnica que regula los contratos de adhesion de servicios</a>
        <a href="uploads/RESOLUCION-NORMA-BASICA-PARA-COMPARTICION-INFRAESTRUCTURA-DICIEMBRE 2017.pdf" target="_blank">Compartición de infraestructura</a>
      </div>
      <div class="box four subitems flex-column-center d" v-scrollanimation>
        <a href="" target="_blank">Calidad del Servicio 1er Semestre del 2025</a>
        <a href="" target="_blank">Calidad del Servicio 2do Semestre del 2025</a>
        <a href="uploads/formato-subir-relacliente-i-semestre-2024.xlsx" target="_blank">Calidad del Servicio 1er Semestre del 2024</a>
        <a href="Formato-subir-RelaCliente-II-SEMESTRE-2024" target="_blank">Calidad de servicio 2do semestre del 2024</a>
      </div>
      <div class="box five last subitems flex-column-center e" v-scrollanimation>
        <a>Tu opinión es importante</a>
        <a @click="openModal">Oportunidades de empleo</a>
        <a>Tarifario y promociones</a>
        <a>Oferta corporativa</a>
      </div>
    </div>

    
  </div>
</template>

<script>

import { storage } from "../config/firebase/storage";
import { db } from "../config/firebase/db";
import  { timestamp } from "../config/firebase/app"
import Status from "../components/Status";

export default {
  data: function() { 
    return {
      //path: "http://localhost:8080/",
      //path: "https://nedetel-43cc9.web.app/",
      path: "https://www.nedetel.net/",
      modal: {
        class: {
          modal: true,
          active: false
        }
      },
      status: {
        waiting: false,
        tipo: 0,
        mostrar: false,
        mensaje: ""
      },
      form: {
        names: {
          value: null,
          placeholder: "Ingrese sus nombres"
        },
        surnames: {
          value: null,
          placeholder: "Ingrese sus apellidos"
        },
        email: {
          value: null,
          placeholder: "Ingrese su correo electrónico"
        },
        phone: {
          value: null,
          placeholder: "Ej. +593 987654321 "
        },
        topic: {
          value: "SOLICITUD DE EMPLEO"
        },
        message: {
          value: null,
          placeholder: "Escribe aquí tu mensaje"
        },
        doc: {
          url: null,
          value: null,
          cv: null,
          name: null
        }
      }
    };
  },
  created() {
    document.body.style.overflow = "auto";
  },
  methods: {
    openModal: function() { 
      document.body.style.overflow = "hidden";
      this.modal.class.active = true;
    },
    closeModal: function() { 
      document.body.style.overflow = "auto";
      this.modal.class.active = false;
      this.cleanForm();
    },
    loadSettingsDocs: function(e) { 
      let file = e.target.files[0];
      this.form.doc.cv = URL.createObjectURL(file);
      this.form.doc.name = file.name;
      this.form.doc.value = file;
    },
    changeStatus: function(value) {
      this.status.mostrar = value;
    },
    changeStatusTo: function(tipo, mensaje, mostrar) {
      this.status.tipo = tipo;
      this.status.mensaje = mensaje;
      this.status.mostrar = mostrar;
    },

    /* Proceso para enviar el correo */
    getData: function() {
      return {
        date: timestamp,
        names: this.form.names.value,
        surnames: this.form.surnames.value,
        email: this.form.email.value,
        phone: this.form.phone.value,
        topic: this.form.topic.value,
        message: this.form.message.value,
        doc: this.form.doc.url
      }
    },

    checkData: function() {
      let res = false;
      let { names, surnames, email, doc, phone } = this.form;
      if(names.value && surnames.value && email.value && doc.cv && phone.value){
        res =  true;
      } 
      return res;
    },

    saveFileStorage: async function(cb) {
      let ref = storage.ref();
      let { doc } = this.form;
      let snapshot = await ref.child("CV/" + doc.name).put(doc.value);
      let url = await snapshot.ref.getDownloadURL();
      cb(url);
    }, 

    sendCVToAdmin: function(data) {
      return this.axios.post(
        //"http://localhost:5001/nedetel-43cc9/us-central1/sendCVToAdmin",
        "https://us-central1-nedetel-43cc9.cloudfunctions.net/sendCVToAdmin",
        data
      );
    },

    sendCVToUser: function(data) {
      console.log("voy a enviar", data);
      return this.axios.post(
        //"http://localhost:5001/nedetel-43cc9/us-central1/sendCVToUser",
        "https://us-central1-nedetel-43cc9.cloudfunctions.net/sendCVToUser",
        data
      );
    },

    cleanForm: function() {
      this.$refs.doc.value = null;
      this.form = {
        names: {
          value: null,
          placeholder: "Ingrese sus nombres"
        },
        surnames: {
          value: null,
          placeholder: "Ingrese sus apellidos"
        },
        email: {
          value: null,
          placeholder: "Ingrese su correo electrónico"
        },
        phone: {
          value: null,
          placeholder: "Ejm. +593 987654321 "
        },
        topic: {
          value: "SOLICITUD DE EMPLEO"
        },
        message: {
          value: null,
          placeholder: "Escribe aquí tu mensaje"
        },
        doc: {
          url: null,
          value: null,
          cv: null,
          name: null
        }
      };
    },

    sendCV: function() { 
      let data = this.getData();
      let { doc } = this.form;
      if(this.$refs.doc.value !== null){
        if(this.checkData) {
          this.changeStatusTo(0, "Enviando, por favor espere...", true);
          this.saveFileStorage(async url => {
            data.doc = url;
            let ad = await this.sendCVToAdmin(data);
            let us = await this.sendCVToUser(data);
            this.changeStatusTo(ad.data.code === 0 ? 2 : 1, ad.data.data, true);
            this.cleanForm();
          });
        } else {
          this.changeStatusTo(1, "Aún existen campos incompletos", true);
        }
      } else {
        this.changeStatusTo(1, "No existe hoja de vida", true);
      }
    }
  },
  components: {
    Status
  }
};

</script>

<style>

.modal {
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  align-items: center;
  justify-content: center;

  transition: all 0.3s 0.3s ease-in;
}

.modal.active {
  display: flex;
  transition: all 0.3s 0.3s ease-in-out;
}

.modal .box {
  background: #fff;
  width: calc(50% - 0.8vw);
  height: calc(46vw - 0.8vw);
  border-radius: 8px;
  border: solid 0.4vw #1A4C94;
  transition: all 0.3s 0.3s ease-in;
}

.modal .box .body {
  height: 100%;
  width: 100%;
  position: relative;
}

.modal .box .body .icon-cv {
  position: absolute;
  color: #1A4C94;
  width: 1.5vw;
  height: 1.5vw;
  top: 0.5vw;
  right: 0.5vw;

  z-index: 99999;
  transform: scale(0.98);
  transition: all 0.1s 0.1s ease-in;
}

.modal .box .body .icon-cv:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.1s 0.1s ease-in-out;
}

.modal .box .body .titulo {
  display: block;
  font-size: 1.4vw;
  width: 100%;
  text-align: center;
  color: #1A4C94;
  padding-top: 2vw;
  text-transform: uppercase;
}

.modal .box .body form {
  width: 85%;
  padding: 3vw 0 3vw 0;
  height: calc(100% - 9.6vw);
  display: block;
  margin: 0 auto;
  justify-content: space-evenly;
}

.modal .box .body form .row-inputs {
  width: 100%;
  height: auto;
  margin-bottom: 0.7vw;
}

.modal .box .body form .row-inputs .middle.a {
  width: calc(100% - 0.5vw);
  padding-right: 0.5vw;
}

.modal .box .body form .row-inputs .middle.b {
  width: calc(100% - 0.5vw);
  padding-left: 0.5vw;
}

.modal .box .body form .label {
  margin-bottom: 0.7vw;
  width: calc(100% - 1vw);
  text-align: left;
  padding-left: 1vw;
  color: #1A4C94;
}
.modal .box .body form .label span {
  width: 100%;
  text-align: left;
}

.modal .box .body form .label .obl { 
  margin-left: 0.2vw;
  color: red;
  width: auto;
}

.modal .box .body form input {
  width: calc(100% - 2vw);
  font-size: 0.9vw;
  font-family: montserrat_light;
  border-radius: 1vw;
  background: #f4f4f4;
  border: none;
  padding: 1vw;
  outline-width: 0;
}
.modal .box .body form input:focus,
.modal .box .body form textarea:focus {
  box-shadow: 0 0 0.5vw 0.05vw #1A4C94;
}

.modal .box .body form textarea {
  margin-bottom: 0.7vw;
  width: calc(100% - 2vw);
  font-size: .9vw;
  font-family: montserrat_light;
  background: #f4f4f4;
  border-radius: 1vw;
  border: none;
  padding: 1vw;
  outline-width: 0;
  height: calc(25% - 2vw);
  resize: none;
}

.modal .box .body form input.btn {
  background: #1A4C94;
  font-family: montserrat_medium;
  text-transform: uppercase;
  color: #fff;
  font-size: 0.9vw;
  cursor: pointer;
  margin: 0.7vw auto 0 auto;
  display: block;
  width: 100%;
}

.footer {
  width: 100%;
  height: 35vw;
  position: relative;
}

.footer .titulos {
  width: 100%;
}

.footer .titulos .box {
  width: calc(25% - 1vw);
  height: 100%;
  padding: 0 0.5vw 0 0.5vw;
}

.footer .titulos .box .item {
  color: #1A4C94;
  font-size: 1.4vw;
}

.footer .titulos .box.subitems {
  height: calc(100% - 2vw);
  padding-top: 2vw;
  justify-content: flex-start;
}

.footer .titulos .box.first.subitems {
  padding-top: 2vw;
  justify-content: flex-start;
}

.footer .titulos .box.last.subitems {
  padding-top: 2vw;
  justify-content: flex-start;
}

.footer .titulos .box.subitems a {
  width: 100%;
  display: block;
  text-decoration: none;
  margin-bottom: 1vw;
  color: #1A4C94;
}

.footer .titulos .box.subitems a:hover { 
  cursor: pointer;
}

.footer .titulos .box.subitems span {
  color: #1A4C94;
}

.footer .titulos .box.subitems.a span.phone {
  margin-bottom: 0;
  font-family: unisans_regular;
  margin-bottom: 0.2vw;
}

.footer .titulos .box.subitems.a span.phone.two {
  margin-bottom: 1vw;
}

.footer .titulos .box.subitems.a span.mail {
  margin-bottom: 2vw;
}

.footer .titulos .box.subitems.a .social {
  
}

.footer .titulos .box.subitems.a .social a {
  width: calc(3vw - 1.5vw);
  height: calc(3vw - 1.5vw);
  text-decoration: none;
  margin-right: 0.8vw;
  border-radius: 50%;
  padding: 0.5vw;
  background: #fff;
  border: solid 1.8px #1A4C94;
}

.footer .titulos .box.subitems.a .social a .icon {
  width: 100%;
  height: 100%;
  font-size: 0.85vw;
  display: block;
  color: #1A4C94;
}

.footer .titulos .box.first {
  width: calc(25% - 0.5vw);
  padding: 0 0.5vw 0 0;
}

.footer .titulos .box.first .cont-img {
  width: 12vw;
  height: 3vw;
}

.footer .titulos .box.first .cont-img img {
  width: 88%;
  height: 85%;
}

.footer .titulos .box.last {
  width: calc(25% - 0.5vw);
  padding: 0 0 0 0.5vw;
}

.footer .barra {
  width: 100%;
  position: relative;
}

.footer .barra span {
  width: 100%;
  height: 1.5px;
  display: block;
  background: #1A4C94;
}

@media only screen and (max-width: 320px) {
  .footer {
    height: 45vw;
  }
  .footer .box.first {
    display: none;
  }
  .footer .box.second {
    display: none;
  }

  .footer .titulos .box {
    width: 33.333333334%;
  }

  .footer .titulos .box .item {
    font-size: 3vw;
  }

  .footer .titulos .box.subitems a {
    font-size: 2vw;
    margin-bottom: 1.8vw;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .footer {
    height: 50vw;
  }
  .footer .box.first {
    display: none;
  }
  .footer .box.second {
    display: none;
  }
  .footer .titulos .box {
    width: 33.333333334%;
  }
  .footer .titulos .box .item {
    font-size: 3vw;
  }

  .footer .titulos .box.subitems a {
    font-size: 2.1vw;
    margin-bottom: 1.8vw;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .footer {
    height: 50vw;
  }
  .footer .box.first {
    display: none;
  }
  .footer .box.second {
    display: none;
  }
  .footer .titulos .box {
    width: 33.333333334%;
  }
  .footer .titulos .box .item {
    font-size: 3vw;
  }
  .footer .titulos .box.subitems a {
    font-size: 1.8vw;
    margin-bottom: 1.8vw;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
}

</style>
